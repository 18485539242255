import axios from 'axios'
const URL_API = '/api'

const getCanais = async () => {
    try {
        const response = await axios.get(`${URL_API}/canal`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro getCanais', e)
        throw (e)
    }
}

const getListaBasicaCanais = async () => {
    try {
        const response = await axios.get(`${URL_API}/canal/lista-basica`);
        return response ? response.data : null;
    } catch (e) {
        console.log('erro getListaBasicaCanais', e);
        throw (e);
    }
}

const adicionar = async (canal) => {
    try {
        delete canal.Id;
        const response = await axios.post(`${URL_API}/canal`, canal)
        return response ? response.data : null
    } catch (e) {
        console.log('erro adicionarCanal', e)
        throw (e)
    }
}

const editar = async (canal) => {
    try {
        const response = await axios.put(`${URL_API}/canal`, canal)
        return response ? response.data : null
    } catch (e) {
        console.log('erro editarCanal', e)
        throw (e)
    }
}

const remover = async (id) => {
    try {
        const response = await axios.delete(`${URL_API}/canal/${id}`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro removerCanal', e)
        throw (e)
    }
}

const getTiposCanaisHabilitados = async () => {
    try {
        const response = await axios.get(`${URL_API}/canal/tipos-habilitados`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro getTiposCanaisHabilitados', e)
        throw (e)
    }
}

const getTiposCanais = async () => {
    try {
        const response = await axios.get(`${URL_API}/canal/tipos`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro getTiposCanais', e)
        throw (e)
    }
}

const putCanalPergunta = async (canal) => {
    try {
        const response = await axios.put(`${URL_API}/canal/pergunta/${canal.id}/${canal.perguntaId}`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro putCanalPergunta', e)
        throw (e)
    }
}

const putCanalSetor = async (canal) => {
    try {
        const response = await axios.put(`${URL_API}/canal/setor/${canal.id}/${canal.setorId}`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro putCanalSetor', e)
    }
}

const getCanaisUsuario = async (idUsuario) => {
    try {
        const response = await axios.get(`${URL_API}/canal/usuario/${idUsuario}`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro getCanaisUsuario', e)
        throw (e)
    }
}

export default {
    getCanais,
    getListaBasicaCanais,
    adicionar,
    editar,
    remover,
    getTiposCanais,
    getTiposCanaisHabilitados,
    putCanalPergunta,
    putCanalSetor,
    getCanaisUsuario,
}