import ModuloEmailServices from '@/assets/js/services/ModuloEmailServices'
import SetoresServices from '@/assets/js/services/SetoresServices'

export default {
    namespaced: true,

    state: {
        mensagens_email: null,
        setores_email: null,
        mensagens_loading: false,
    },

    getters: {
        mensagensEmail(state) {
            return state.mensagens_email;
        },
        mensagensEmailLoading(state) {
            return state.mensagens_loading;
        },
        setoresEmail(state) {
            return state.setores_email;
        },
    },

    mutations: {
        SET_MENSAGENS_EMAIL(state, msgs) {
            state.mensagens_email = msgs
        },
        SET_MENSAGENS_EMAIL_LOADING(state, isLoading) {
            state.mensagens_loading = isLoading
        },
        SET_SETORES_EMAIL(state, setores) {
            state.setores_email = setores
        },
    },

    actions: {
        async buscaMensagens({ commit }) {
            try {
                commit('SET_MENSAGENS_EMAIL_LOADING', true)
                const msgs = await ModuloEmailServices.buscaMensagens()
                commit('SET_MENSAGENS_EMAIL', msgs)
            } catch (e) {
                console.log(e)
            }
            commit('SET_MENSAGENS_EMAIL_LOADING', false)
        },
        async buscaSetoresEmail({ commit }) {
            try {
                const setores = await SetoresServices.getSetoresEmail()
                commit('SET_SETORES_EMAIL', setores)
                return
            } catch (e) {
                console.log(e)
                console.log(e.response)
                return
            }
        },
        async adicionar({ dispatch }, msg) {
            try {
                await ModuloEmailServices.criarMensagem(msg)
                dispatch('buscaMensagens')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async editar({ dispatch }, msg) {
            try {
                await ModuloEmailServices.editarMensagem(msg)
                dispatch('buscaMensagens')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async remover({ dispatch }, msgId) {
            try {
                await ModuloEmailServices.removerMensagem(msgId)
                dispatch('buscaMensagens')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
    },
}
