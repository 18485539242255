import axios from 'axios'
const URL_API = '/api'

const getTabulacaoDoAtendimento = async (atendimentoId) => {
    try {
        const { data } = await axios.get(`${URL_API}/Atendimento/tabulacao/${atendimentoId}`)
        return data
    } catch (e) {
        console.log('erro getTabulacaoAtendimento', e)
        throw(e)
    }
}

const salvarTabulacao = async (atendimentoId, tabulacaoAtendimento) => {
    try {
        const { data } = await axios.post(`${URL_API}/AtendimentoTabulacao/${atendimentoId}`, tabulacaoAtendimento )
        return data
    } catch (e) {
        console.log('erro salvarTabulacao', e)
        throw(e)
    }
}

const salvarAtendimentoTabulacaoMensagemPreDefinida = async (dadosAtendimentoTabulacao) => {
    try {
        const { data } = await axios.post(`${URL_API}/AtendimentoTabulacao/${dadosAtendimentoTabulacao.idAtendimento}/${dadosAtendimentoTabulacao.idMensagemPreDefinida}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'setAtendimentoTabulacao')
        throw (e)
    }
}

export default {
    getTabulacaoDoAtendimento,
    salvarTabulacao,
    salvarAtendimentoTabulacaoMensagemPreDefinida
}
