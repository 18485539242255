import axios from 'axios'
const URL_API = '/api'

const getChatConfig = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/chatConfiguracao`)
        return data
    } catch (e) {
        console.log('erro getChatConfig', e)
        throw (e)
    }
}

const postChatConfig = async (chatConfig) => {
    try {
        const { data } = await axios.post(`${URL_API}/chatConfiguracao`, chatConfig)
        return data
    } catch (e) {
        console.log('erro postChatConfig', e)
        throw (e)
    }
}

const getChatKey = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/Chat/chat-key`)
        return data
    } catch (e) {
        console.log('erro getChatKey', e)
        throw (e)
    }
}

export default {
    getChatConfig,
    postChatConfig,
    getChatKey
}