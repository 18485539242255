import axios from 'axios'
import LogServices from '@/assets/js/services/LogServices'

const URL_API = '/api'

const buscaMensagens = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/ModuloEmail/mensagens`)
        return data?? null
    } catch (e) {
        LogServices.logErro(e, 'buscaMensagensModuloEmail')
        throw(e)
    }
}

const criarMensagem = async (mensagem) => {
    try {
        let novaMensagem = { ...mensagem }
        if(novaMensagem.Id !== undefined) {
            delete novaMensagem.Id
        }
        
        const { data } = await axios.post(`${URL_API}/ModuloEmail/CadastroMensagem`, novaMensagem)
        return data?? null
    } catch (e) {
        LogServices.logErro(e, 'criarMensagemModuloEmail')
        throw(e)
    }
}

const editarMensagem = async (mensagem) => {
    try {
        const { data } = await axios.put(`${URL_API}/ModuloEmail/EditarMensagem`, mensagem)
        return data?? null
    } catch (e) {
        LogServices.logErro(e, 'editarMensagemModuloEmail')
        throw(e)
    }
}

const removerMensagem = async (idMsg) => {
    try {
        const { data } = await axios.delete(`${URL_API}/ModuloEmail/ExcluiMensagem/${idMsg}`)
        return data?? null
    } catch (e) {
        LogServices.logErro(e, 'removerMensagemModuloEmail')
        throw(e)
    }
}

const salvarSac = async (email) => {
    try {
        const { data } = await axios.post(`${URL_API}/ModuloEmail/SalvarSac`, `"${email}"`, { headers: { 'Content-Type': 'application/json' } })
        return data?? null
    } catch (e) {
        LogServices.logErro(e, 'salvarSac')
        throw(e)
    }
}

const buscaMensagensDisponiveis = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/ModuloEmail/mensagens-disponiveis`)
        return data?? null
    } catch (e) {
        LogServices.logErro(e, 'buscaMensagensDisponiveis')
        throw(e)
    }
}

export default {
    buscaMensagens,
    criarMensagem,
    editarMensagem,
    removerMensagem,
    salvarSac,
    buscaMensagensDisponiveis
}
