import axios from 'axios'
import LogServices from '@/assets/js/services/LogServices'

const URL_API = '/api'

const filtrarConsultaAtendimento = async (filtro) => {
    try {
        const response = await axios.post(`${URL_API}/atendimento/FiltrarConsultaAtendimento`, filtro)
        return response ? response.data : null;
    } catch (e) {
        LogServices.logErro(e, 'teste')
        throw (e)
    }
}

// Recebe as datas no formato aaaa-mm-dd
const getAllAtendimentos = async (dataInicio, dataFim) => {
    try {
        let query = ''
        if (dataInicio || dataFim) {
            query += `?`
        }
        if (dataInicio) {
            query += `dataInicio=${dataInicio}`
        }
        if (dataInicio && dataFim) {
            query += '&'
        }
        if (dataFim) {
            query += `dataFim=${dataFim}`
        }

        const response = await axios.get(`${URL_API}/atendimento/all/${query}`)
        return response ? response.data : null
    } catch (e) {
        LogServices.logErro(e, 'getAtendimentos')
        throw (e)
    }
}
//const getAtendimentoTabulacao = async (dataInicio, dataFim, origem, status, atendente, listaTabulacaoPaiId, pesquisadoPorProtocolo, selecionado) => {
//    try {
//        let query = ''
//        if (dataInicio || dataFim || atendente) {
//            query += `?`
//        }
//        if (dataInicio) {
//            query += `DataInicio=${dataInicio}`
//        }
//        if (dataInicio && dataFim) {
//            query += '&'
//        }
//        if (dataFim) {
//            query += `DataFim=${dataFim}`
//        }
//        if (origem) {
//            query += `&Origem=${origem}`
//        }
//        if (status) {
//            query += `&Status=${status}`
//        }
//        if (atendente) {
//            query += `&Atendente=${atendente}`
//        }
//        if (pesquisadoPorProtocolo) {
//            query += `&CodigoProtocolo=${pesquisadoPorProtocolo}`
//        }
//        //if (listaTabulacaoPaiId || listaTabulacaoPaiId.length != 0) {
//        //    query += `&ListaTabulacaoPaiId=${listaTabulacaoPaiId}`
//        //}

//        var data = JSON.stringify({
//            "ListaTabulacaoPaiId": listaTabulacaoPaiId
//        });

//        var config = {
//            method: 'post',
//            url: `${URL_API}/atendimento/FiltrarConsultaAtendimento/${query}&Offset=${selecionado}&IsExportacao=${false}`,
//            headers: {
//                'Content-Type': 'application/json'
//            },
//            data: data
//        };

//        /*const response = await axios.get(`${URL_API}/atendimento/getAtendimentoTabulacao/${query}`)*/
//        const response = await axios(config)

//        return response.data.Quantidades != 0 ? response.data : null
//    } catch (e) {
//        LogServices.logErro(e, 'getAtendimentoTabulacao')
//        throw (e)
//    }
//}
const getUltimosAtendimentos = async () => {
    try {
        const response = await axios.get(`${URL_API}/atendimento/UltimosAtendimentos`)
        return response ? response.data : null
    } catch (e) {
        LogServices.logErro(e, 'getUltimosAtendimentos')
        throw (e)
    }
}

const getAtendimentos = async () => {
    /*try {*/
    const response = await axios.get(`${URL_API}/atendimento`)
    return response ? response.data : null
    //} catch (e) {
    //    LogServices.logErro(e, 'getAtendimentos')
    //    throw(e)
    //}
}

const getAtendimentosAtivoAndPendentes = async () => {
    const response = await axios.get(`${URL_API}/atendimento/getAtendimentosAtivoAndPendentes`)
    return response ? response.data : null
}

const getDadosAtendimento = async (idAtendimento) => {
    try {
        const response = await axios.get(`${URL_API}/atendimento/DetalhesAtendimento/${idAtendimento}`)
        return response ? response.data : null
    } catch (e) {
        LogServices.logErro(e, 'getDadosAtendimento')
        throw (e)
    }
}

const discar = async (numeroCliente, ramal = 1) => {
    try {
        const response = await axios.post(`${URL_API}/atendimento/EnviaTelefonia/${numeroCliente}${ramal ? '?NumeroRamal=' + ramal : ''}`, { NumeroRamal: ramal })
        return response ? response.data : null
    } catch (e) {
        LogServices.logErro(e, 'discar')
        throw (e)
    }
}

const finalizaAtendimento = async (idAtendimento, empresaId) => {
    try {
        const response = await axios.post(`${URL_API}/atendimento/FinalizaAtendimento/${idAtendimento}`)
        // RetornoViewModel tem mensagem de erro
        if (response && response.data && (response.data.Sucesso == false)) {
            throw new Error(response.data.Mensagem)
        }
        return response? response.data: null
    } catch (erro) {
        LogServices.logErro(erro, 'finalizaAtendimento')
        throw erro
    }
}

const getHistoricoAtendimento = async (atendimentoId) => {
    try {
        const response = await axios.get(`${URL_API}/AtendimentoLog/${atendimentoId}`)
        return response ? response.data : null
    } catch (e) {
        LogServices.logErro(e, 'getHistoricoAtendimento')
        throw (e)
    }
}

const getAtendimentosAnteriores = async (atendimentoId, usuarioId) => {
     try {
    const response = await axios.get(`${URL_API}/Atendimento/GetOldsTickets/${atendimentoId}${usuarioId ? `&UsuarioId=${usuarioId}` : ''}`)
    return response ? response.data : null

    } catch (e) {
        LogServices.logErro(e, 'getAtendimentosAnteriores')
        throw(e)
    }
}

const getAtendimentosCliente = async (clienteId) => {
    try {
        const response = await axios.get(`${URL_API}/Atendimento/GetOldsTicketsCliente/${clienteId}`)
        return response ? response.data : null
    } catch (e) {
        LogServices.logErro(e, 'getAtendimentosCliente')
        throw (e)
    }
}

const getConversa = async (atendimentoId, tipo) => {
    try {
        const response = await axios.get(`${URL_API}/Atendimento/BuscaConversa/${atendimentoId}${tipo ? `&Tipo=${tipo}` : ''}`)
        return response ? response.data : null
    } catch (e) {
        LogServices.logErro(e, 'getConversa')
        throw (e)
    }
}

const encaminharAtendimento = async (atendimentoId, setorId, empresaId) => {
    try {
        const { data } = await axios.post(`${URL_API}/Atendimento/EncaminhaSetorAtendimento`, { atendimentoId, setorId })
        if (!data) {
            throw new Error('Retorno da operação inválido')
        }

        if (!data.Sucesso) {
            throw new Error(data.Mensagem)
        }


        return { mensagem: data.Mensagem, sucesso: data.Sucesso }
    } catch (e) {
        LogServices.logErro(e, 'encaminharAtendimento')
        throw (e)
    }
}

const getAgendamentos = async (usuarioId) => {
    try {
        const { data } = await axios.get(`${URL_API}/Atendimento/BuscaAgendamentos/${usuarioId}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'getAgendamentos')
        throw (e)
    }
}

const getAgendamentosAtivos = async (usuarioId) => {
    try {
        const { data } = await axios.get(`${URL_API}/Atendimento/BuscaAgendamentosAtivos/${usuarioId}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'getAgendamentosAtivos')
        throw (e)
    }
}

const reativarAtendimento = async (atendimentoId, agendamentoId) => {
    try {
        const { data } = await axios.post(`${URL_API}/Atendimento/ReativarChamado?atendimentoId=${atendimentoId}&agendamentoId=${agendamentoId}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'reativarAtendimento')
        throw (e)
    }
}

const reativarAtendimentoPorId = async (atendimentoId, usuarioId) => {
    try {
        const { data } = await axios.post(`${URL_API}/Atendimento/ReativarChamadoPorId/${atendimentoId}/?UsuarioId=${usuarioId}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'reativarAtendimentoPorId')
        throw (e)
    }
}

const criarAgendamento = async (atendimentoId, usuarioId, dataAgendada, ticket) => {
    const novoAgendamento = {
        AtendimentoId: atendimentoId,
        UsuarioId: usuarioId,
        DataAgendada: dataAgendada,
        Ticket: ticket
    }
    try {
        const { data } = await axios.post(`${URL_API}/Atendimento/CriarAgendamento`, novoAgendamento)
        return data
    } catch (e) {
        LogServices.logErro(e, 'CriarAgendamento')
        throw (e)
    }
}

const insereTags = async (atendimentoId, tags) => {
    try {
        const { data } = await axios.post(`${URL_API}/Atendimento/InsereTag/${atendimentoId}`, tags.split(',').filter(tag => tag))
        return data
    } catch (e) {
        LogServices.logErro(e, 'insereTags')
        throw (e)
    }
}

const AtendimentoAtivoSemPABX = async (origem, tabulacaoId, colunaId) => {
    const dados = {
        Origem: origem,
        TabulacaId: tabulacaoId,
        ColunaId: colunaId
    }
    try {
        const { data } = await axios.post(`${URL_API}/AtendimentoAtivo/atendimento-sem-pabx/`, dados);
        return data
    } catch (e) {
        LogServices.logErro(e, 'AtendimentoAtivoSemPABX')
        throw (e)
    }
}

const getEnumStatus = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/Atendimento/GetEnumAtendimentoStatus`)
        return data?.filter(item => ![3, 4].includes(item.Value));
    } catch (e) {
        LogServices.logErro(e, 'getEnumStatus')
        throw (e)
    }
}

const getEnumOrigem = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/Atendimento/GetEnumAtendimentoOrigem`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'getEnumOrigem')
        throw (e)
    }
}
const getSelectTabulacao = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/Atendimento/GetSelectTabulacao`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'getSelectTabulacao')
        throw (e)
    }
}
const buscarCliente = async (documento) => {
    try {
        const { data } = await axios.post(`${URL_API}/Atendimento/BuscaCliente`, `"${documento}"`, { headers: { 'Content-Type': 'application/json' } })
        if (data && data.Sucesso == false) {
            throw new Error('Cliente não encontrado')
        }
        return data
    } catch (e) {
        LogServices.logErro(e, 'buscaCliente')
        throw (e)
    }
}

const buscarMensagensPaginada = async (dataInicial, dataFinal, origem, nroPagina, tamanhoPagina) => {
    let query = ''
    let temQuery = false

    if (dataInicial) {
        query += `?`
        temQuery = true
        query += `dataInicial=${dataInicial}`
    }

    if (dataFinal) {
        temQuery ? query += '&' : `?`
        temQuery = true
        query += `dataFinal=${dataFinal}`
    }

    if (origem) {
        temQuery ? query += '&' : `?`
        temQuery = true
        query += `origem=${origem}`
    }

    if (nroPagina) {
        temQuery ? query += '&' : `?`
        temQuery = true
        query += `nroPagina=${nroPagina}`
    }

    if (tamanhoPagina) {
        temQuery ? query += '&' : `?`
        temQuery = true
        query += `tamanhoPagina=${tamanhoPagina}`
    }

    try {
        const { data } = await axios.get(`${URL_API}/Atendimento/BuscaMensagensPaginada/${query}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'buscarMensagensPaginada')
        throw (e)
    }
}

const buscarTodasMensagens = async (dataInicial, dataFinal, origem) => {
    let query = ''

    query += `?trazerTodas=true`
    if (dataInicial) {
        query += `&dataInicial=${dataInicial}`
    }

    if (dataFinal) {
        query += `&dataFinal=${dataFinal}`
    }

    if (origem) {
        query += `&origem=${origem}`
    }

    try {
        const { data } = await axios.get(`${URL_API}/Atendimento/BuscaMensagens/${query}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'buscaTodasMensagens')
        throw (e)
    }
}

const enviaTelegram = async (chatId, msg) => {
    try {
        const { data } = await axios.post(`${URL_API}/Atendimento/RespondeTelegram/${chatId}`, `"${msg}"`, { headers: { 'Content-Type': 'application/json' } })
        return data
    } catch (e) {
        LogServices.logErro(e, 'enviaTelegram')
        throw (e)
    }
}
const EnviaWhatsWeb = async (chatId, msg) => {
    try {
        const { data } = await axios.post(`${URL_API}/WhatsWeb/RespondeWhatsWeb/${chatId}`, `"${msg}"`, { headers: { 'Content-Type': 'application/json' } })
        return data
    } catch (e) {
        LogServices.logErro(e, 'enviaww')
        throw (e)
    }
}

const buscarAnexos = async (atendimentoId) => {
    try {
        const { data } = await axios.get(`${URL_API}/Atendimento/GetAnexos/${atendimentoId}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'buscarAnexos')
    }
}
const buscarAgendadosDia = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/Atendimento/BuscarAgendadosDia`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'buscaAgendadosDia')
        throw (e)
    }
}
const uploadArquivoParaEnvio = async (arquivo) => {
    try {
        const formData = new FormData()
        formData.append('file', arquivo)

        const { data } = await axios.post(`${URL_API}/WhatsWeb/uploadArquivoParaEnvio`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        return data
    } catch (e) {
        LogServices.logErro(e, 'uploadArquivoParaEnvio')
        throw (e)
    }
}
const getMailingsPendentes = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/atendimentoAtivo/GetMailingsPendentes`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'buscaAgendadosDia')
        throw (e)
    }
}

export default {
    getAllAtendimentos,
    getAtendimentos,
    getDadosAtendimento,
    getAtendimentosAnteriores,
    getAtendimentosCliente,
    getHistoricoAtendimento,
    getConversa,
    getAgendamentos,
    getAgendamentosAtivos,
    discar,
    finalizaAtendimento,
    encaminharAtendimento,
    reativarAtendimento,
    reativarAtendimentoPorId,
    criarAgendamento,
    insereTags,
    AtendimentoAtivoSemPABX,
    getEnumStatus,
    getEnumOrigem,
    buscarCliente,
    buscarTodasMensagens,
    buscarMensagensPaginada,
    enviaTelegram,
    EnviaWhatsWeb,
    buscarAnexos,
    buscarAgendadosDia,
    getUltimosAtendimentos,
    //getAtendimentoTabulacao,
    getSelectTabulacao,
    uploadArquivoParaEnvio,
    getMailingsPendentes,
    getAtendimentosAtivoAndPendentes,

    filtrarConsultaAtendimento
}