import AtendimentoServices from '@/assets/js/services/AtendimentoServices'
import AtendimentoTabulacaoServices from '@/assets/js/services/AtendimentoTabulacaoServices'
import TabulacaoServices from '@/assets/js/services/TabulacaoServices'

const SelectBox = 3

export default {
    namespaced: true,

    state: {
        lista_atendimentos: null,
        lista_id_atendimentos: null,
        atendimento_selecionado: null,
     

        mensagens_conversa: null,
        mensagens_loading: false,

        perguntas_tabulacao: null,
        perguntas_loading: false,

        itens_tabulacao: null,

        historico: null,
        historico_loading: false,

        atendimentos_anteriores: null,
        atendimentos_anteriores_loading: false,

        agendamentos: null,
        agendamentos_ativos: null,

        enum_origem: null,
        enum_status: null,

        lista_agendados_dia:null,

        lista_selects_tabulacao:null,
    },

    getters: {
        listaAtendimentos(state) {
            return state.lista_atendimentos
        },
        listaIdAtendimentos(state) {
            return state.lista_id_atendimentos
        },
        atendimentoSelecionado(state) {
            return state.atendimento_selecionado
        },
        
        mensagensConversa(state) {
            return state.mensagens_conversa
        },
        mensagensConversaLoading(state) {
            return state.mensagens_loading
        },
        perguntasTabulacao(state) {
            return state.perguntas_tabulacao
        },
        itensTabulacao(state) {
            return state.itens_tabulacao
        },
        historico(state) {
            return state.historico
        },
        anteriores(state) {
            return state.atendimentos_anteriores
        },
        dadosAtendimentoLoading(state) {
            return state.perguntas_loading
                || state.historico_loading
        },
        atendimentosAnterioresLoading(state) {
            return state.atendimentos_anteriores_loading
        },
        agendamentos(state) {
            return state.agendamentos
        },
        agendamentosAtivos(state) {
            return state.agendamentos_ativos
        },
        enumOrigem(state) {
            return state.enum_origem
        },
        enumStatus(state) {
            return state.enum_status
        },
        listaSelectTabulacao(state) {
            return state.lista_selects_tabulacao
        },
        listaAgendadosDia(state) {
            return state.lista_agendados_dia
        },
       
        
    },

    mutations: {
        SET_ATENDIMENTOS (state, atendimentos) {
            state.lista_atendimentos = atendimentos
        },
        SET_DADOS_ATENDIMENTOS(state, atendimentos) {
            state.lista_id_atendimentos = atendimentos
        },
        PUSH_ATENDIMENTO(state, atendimento) {
            if(!atendimento) {
                throw new Error('PUSH_ATENDIMENTO nao pode receber atendimento nulo')
            }
            // Se nao foi inicializado, inicializa
            
            if (!state.lista_atendimentos) {
                state.lista_atendimentos = [{ ...atendimento }]
                
                return
            }

            const atendimentoJaEstaNaLista = state.lista_atendimentos
                .find(atend => atend.CodigoProtocolo === atendimento.CodigoProtocolo)
            if(atendimentoJaEstaNaLista) {
                return
            }
            state.lista_atendimentos.push(atendimento)
        },
        POP_ATENDIMENTO (state, idAtend) {
            // Se nao foi inicializado, inicializa
            if(!state.lista_atendimentos || state.lista_atendimentos.length == 0) {
                throw new Error('A mutação POP_ATENDIMENTO foi chamada com a lista vazia')
            }

            state.lista_atendimentos = state.lista_atendimentos.filter(atendimento => atendimento.Id != idAtend)
        },
        SET_ATENDIMENTO_SELECIONADO (state, atendimento) {
            state.atendimento_selecionado = atendimento
        },

       

        SET_CONVERSA (state, conversa) {
            state.mensagens_conversa = conversa
            state.mensagens_loading = false
        },
        SET_CONVERSA_LOADING (state) {
            state.mensagens_loading = true
        },

        SET_TABULACAO (state, perguntas) {
            state.perguntas_tabulacao = perguntas
            state.perguntas_loading = false
        },
        SET_TABULACAO_LOADING (state) {
            state.perguntas_loading = true
        },

        SET_ITENS_TABULACAO (state, itens) {
            state.itens_tabulacao = itens
        },
        PUSH_ITENS_TABULACAO (state, itens) {
            // Tentando subir vazio, return
            if(!itens && itens.length == 0) {
                return
            }
            // Lista ainda nao foi inicializada, inicializa e return
            if(!state.itens_tabulacao) {
                state.itens_tabulacao = [].concat(itens)
                return
            }
            // Percorre itens verificando se ja esta na lista para inserir
            itens.forEach(item => {
                if(!state.itens_tabulacao.some(itemTab => itemTab.Id == item.Id)) {
                    state.itens_tabulacao.push(item)
                }
            })

        },

        SET_HISTORICO (state, historico) {
            state.historico = historico
            state.historico_loading = false
        },
        SET_HISTORICO_LOADING (state) {
            state.historico_loading = true
        },

        SET_ATENDIMENTOS_ANTERIORES (state, anteriores) {
            state.atendimentos_anteriores = anteriores
            state.atendimentos_anteriores_loading = false
        },
        SET_ATENDIMENTOS_ANTERIORES_LOADING (state) {
            state.atendimentos_anteriores_loading = true
        },

        SET_AGENDAMENTOS (state, agendamentos) {
            state.agendamentos = agendamentos
        },
        SET_AGENDAMENTOS_ATIVOS (state, ativos) {
            state.agendamentos_ativos = ativos
        },
        SET_ENUM_ORIGEM (state, enumList) {
            state.enum_origem = enumList
        },
        SET_ENUM_STATUS (state, enumList) {
            state.enum_status = enumList
        },
        SET_LISTA_AGENDADOS_DIA(state,agendados){
            state.lista_agendados_dia = agendados
        },
        SET_LISTA_SELECTS_TABULACAO(state,selects){
            state.lista_selects_tabulacao = selects
        }

    },

    actions: {
        async buscaAtendimentos({ commit }) {
            try {
                const atendimentos = await AtendimentoServices.getAtendimentos()
                commit('SET_ATENDIMENTOS', atendimentos)

                return
            } catch(e) {
                console.log(e)
                return
            }
        },

        async buscaAtendimentosAtivoAndPendentes({ commit }) {
            try {
                const atendimentos = await AtendimentoServices.getAtendimentosAtivoAndPendentes()
                commit('SET_DADOS_ATENDIMENTOS', atendimentos)

                return
            } catch (e) {
                console.log(e)
                return
            }
        },

        async selecionaAtendimento({ commit, dispatch }, idAtendimento) {
            
            try {
                const atendimento = await AtendimentoServices.getDadosAtendimento(idAtendimento)
               
                if (!atendimento) {
                   
                    dispatch('deselecionaAtendimento')
                    return
                }

                commit('SET_ATENDIMENTO_SELECIONADO', { ...atendimento, Id: idAtendimento })
                dispatch('buscaConversa')
                commit('SET_TABULACAO_LOADING')
                commit('SET_HISTORICO_LOADING')
                commit('SET_ATENDIMENTOS_ANTERIORES_LOADING')
                dispatch('buscaTabulacao')
                dispatch('buscaHistorico', idAtendimento)
                dispatch('buscaAtendimentosAnteriores', idAtendimento)
                dispatch('buscaAtendimentos')
                dispatch('atendimentoEmail/buscaAnexosEmail', idAtendimento, {root:true})
                dispatch('atendimentoEmail/atualizarDadosEmail', {
                    assunto: atendimento.AssuntoEmail || '',
                    emailPara: atendimento.EmailRemetente || '',
                    emailCc: ''
                }, {root:true})
                dispatch('atendimentoEmail/limpaAnexos', null, {root:true})
                return
            } catch (e) {
               
                console.log(e)
                dispatch('deselecionaAtendimento')
            }
        },
       
        selecionaAtendimentoConsulta({ commit, dispatch }, atendimento) {
            
            commit('PUSH_ATENDIMENTO', { ...atendimento, modoConsulta: true })
            
            dispatch('selecionaAtendimento', atendimento.Id)
        },
        
        fechaAtendimentoConsulta({ commit, dispatch, state }, idAtendimento) {
            if(state.atendimento_selecionado && state.atendimento_selecionado.Id == idAtendimento) {
                dispatch('deselecionaAtendimento')
            }
            commit('POP_ATENDIMENTO', idAtendimento)
        },

        deselecionaAtendimento({ commit }) {
            commit('SET_ATENDIMENTO_SELECIONADO', null)
            commit('SET_CONVERSA', null)
            commit('SET_TABULACAO', null)
            commit('SET_HISTORICO', null)
            commit('SET_ATENDIMENTOS_ANTERIORES', null)
        },

        async buscaConversa({ commit, state }, { setLoading = true } = {}) {
            if(!state || !state.atendimento_selecionado) {
                return
            }
            const idAtendimento = state.atendimento_selecionado.Id

            if(setLoading) {
                commit('SET_CONVERSA_LOADING')
            }

            try {
                const conversa = await AtendimentoServices.getConversa(idAtendimento)
                commit('SET_CONVERSA', conversa)
            } catch (e) {
                console.log('Erro na busca da conversa', e)
                commit('SET_CONVERSA', null)
            }
        },

        async buscaTabulacao({ commit, state }) {
            try {
                const idAtendimento = state.atendimento_selecionado.Id
                const perguntasTabulacao = await AtendimentoTabulacaoServices.getTabulacaoDoAtendimento(idAtendimento)

                commit('SET_TABULACAO', perguntasTabulacao)
                
                perguntasTabulacao
                    .filter(item => item.Tipo == SelectBox )
                    .map(item => item.TabulacaoId )
                    .forEach(tabulacaoId => {

                    TabulacaoServices.getItensByTabulacaoId(tabulacaoId)
                        .then( itensTabulacao => {
                            commit('PUSH_ITENS_TABULACAO', itensTabulacao)
                        })
                        .catch( erro => console.log('getItensByTabulacaoId', erro))
                })

            } catch (e) {
                console.log('Erro na busca da tabulacao', e)
                commit('SET_TABULACAO', null)
            }
        },

        async buscaHistorico({ commit }, idAtendimento) {
            try {
                const historico = await AtendimentoServices.getHistoricoAtendimento(idAtendimento)
                if(!historico) {
                    commit('SET_HISTORICO', null)
                    return
                }

                commit('SET_HISTORICO', historico)
                return
            } catch(e) {
                console.log("buscaHistorico",e)
                commit('SET_HISTORICO', null)
            }
        },

        async buscaAtendimentosAnteriores({ commit }, idAtendimento) {
            try {
                const atendimentosAnteriores = await AtendimentoServices.getAtendimentosAnteriores(idAtendimento)
                if(!atendimentosAnteriores) {
                    commit('SET_ATENDIMENTOS_ANTERIORES', null)
                    return
                }

                commit('SET_ATENDIMENTOS_ANTERIORES', atendimentosAnteriores)
                return
            } catch(e) {
                console.log("buscaAtendimentosAnteriores", e)
                commit('SET_ATENDIMENTOS_ANTERIORES', null)
            }
        },

        async salvarAtendimentoTabulacao({ commit }, dadosAtendimentoTabulacao) {
            try {
                const salvarAtendimentoTabulacao = await AtendimentoTabulacaoServices.salvarAtendimentoTabulacaoMensagemPreDefinida(dadosAtendimentoTabulacao)
                console.log(salvarAtendimentoTabulacao)
            } catch (err) {
                console.log('salvarAtendimentoTabulacao', err)
            }
        },

        buscaAgendamentos({ commit }, idUsuario) {
            
            AtendimentoServices.getAgendamentos(idUsuario)
                .then( (agendamentos) => {
                    commit('SET_AGENDAMENTOS', agendamentos)
                })
                .catch(err => console.log('getAgendamentos', err))

            AtendimentoServices.getAgendamentosAtivos(idUsuario)
                .then( (agendamentosAtivos) => {
                    commit('SET_AGENDAMENTOS_ATIVOS', agendamentosAtivos)
                })
                .catch(err => console.log('getAgendamentosAtivos', err))
        },

        buscaEnums({ commit }) {
            AtendimentoServices.getEnumStatus()
                .then( (enums) => {
                    commit('SET_ENUM_STATUS', enums)
                })
                .catch(err => console.log('getEnumStatus store', err))

            AtendimentoServices.getEnumOrigem()
                .then( (enums) => {
                    commit('SET_ENUM_ORIGEM', enums)
                })
                .catch(err => console.log('getEnumOrigem store', err))
            
            AtendimentoServices.getSelectTabulacao()
                .then( (selects) =>{
                    commit('SET_LISTA_SELECTS_TABULACAO',selects)
                }) 
                .catch( err => console.log('getSelectTabulacao store',err))
          
        },

        buscaAgendadosDia({commit}){
            AtendimentoServices.buscarAgendadosDia()
            .then( (agendados) =>{
                commit('SET_LISTA_AGENDADOS_DIA',agendados)
            })
            .catch(err => console.log('buscaAgendadosDia sotre',err) )
        }

    },
}
