import SetoresServices from '@/assets/js/services/SetoresServices'

export default {
    namespaced: true,

    state: {
        lista_setores: null,
    },

    getters: {
        listaSetores(state) {
            return state.lista_setores
        }
    },

    mutations: {
        SET_SETORES(state, setores) {
            state.lista_setores = setores
        },
    },

    actions: {
        async buscaSetores({ commit }) {
            try {
                const setores = await SetoresServices.getSetores()
                commit('SET_SETORES', setores)
                return
            } catch(e) {
                console.log(e)
                return
            }
        },
        async adicionar({ dispatch }, setor) {
            try {
                await SetoresServices.adicionar(setor)
                dispatch('buscaSetores')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async editar({ dispatch }, setor) {
            try {
                await SetoresServices.editar(setor)
                dispatch('buscaSetores')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async remover({ dispatch }, data) {
            try {
                await SetoresServices.remover(data)
                dispatch('buscaSetores')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },

    },
}
