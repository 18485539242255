import axios from 'axios'
const URL_API = '/api'

const carregaConversaV2 = async (dados) => {
    try {
        const { data } = await axios.get(`${URL_API}/relatorios/ConscultaConversasV2?` + dados)

        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro carregar categoria Conversas V2', e)
        throw (e)
    }
}

const carregaRendimento = async () => {
    try {
        $("#caixa_carregamento").show();
        const { data } = await axios.get(`api/relatorios/RelatorioRendimento`)
        return data
    } catch (e) {
        console.log('erro carregar rendimento dos usuarios', e)
        throw (e)
    } finally {
        $("#caixa_carregamento").hide()
    }
}

const carregaMensagemSProtocolo = async (dados) => {
    try {
        const { data } = await axios.get(`${URL_API}/relatorios/RelatorioMensagensSemProtocolo?` + dados)

        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro carregar categoria Conversas V2', e)
        throw (e)
    }
}

const filtroOuPadraoRelatorioDePausas = async (dadosFiltro) => {
    try {
        const { data } = await axios.get(`${URL_API}/relatorios/filtroOuPadraoRelatorioDePausas`, {
            params: {
                'SetorId': dadosFiltro.SetorId,
                'UsuarioId': dadosFiltro.UsuarioId,
                'Inicio': dadosFiltro.DataInicio,
                'Fim': dadosFiltro.DataFim,
                'OffsetUsuario': Math.floor(dadosFiltro.OffsetUsuario * 10),
                'OffsetPausas': Math.floor(dadosFiltro.OffsetPausas * 10)
            }
        });
        return data;
    } catch (e) {
        console.log('erro ao Filtrar Relatorio', e);
        throw (e);
    }
}

const exportarRelatorio = async (dadosFiltroParaExportacao) => {
    try {
        axios.get(`${URL_API}/relatorios/ExportarRelatorioPausas`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                'Inicio': dadosFiltroParaExportacao.DataInicio ?? Date.now(),
                'Fim': dadosFiltroParaExportacao.DataFim ?? Date.now(),
                'TipoRelatorio': dadosFiltroParaExportacao.TipoRelatorio,
            },
        }).then(res => {
            console.log(res.data)
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([res.data]))
            link.download = `relatorio_${dadosFiltroParaExportacao.Nome}_${Date.now()}.xlsx`
            link.click();
            link.remove();
        }).catch(err => {
            console.log(err);
        });
        
    } catch (e) {
        console.log('erro ao exportarRelatorio', e);
        throw (e);
    }
}

const exportarInfoFiltroKanban = async (detalhes) => {
    try {
        console.log(JSON.stringify(detalhes))
        return axios.post(`/api/relatorios/ExportarInfoFiltroKanban`, detalhes, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => {
            console.log(res.data)
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([res.data]))
            link.download = `relatorio_DetalhesInfoFiltroKanban_${Date.now()}.xlsx`
            link.click();
            link.remove();
            $("#caixa_carregamento").hide();
            return false
        }).catch(err => {
            console.log(err);
        });
    } catch (e) {
        console.log('erro ao exportarRelatorio', e);
        throw (e);
    }
}

export default {
    carregaConversaV2,
    carregaMensagemSProtocolo,
    carregaRendimento,
    filtroOuPadraoRelatorioDePausas,
    exportarRelatorio,
    exportarInfoFiltroKanban
}