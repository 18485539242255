import ModuloPadraoServices from '@/assets/js/services/ModuloPadraoServices'

export default {
    namespaced: true,

    state: {
        mensagens_padrao: null,
        setores_padrao: null,
        mensagens_loading: false,
    },

    getters: {
        mensagensPadrao(state) {
            return state.mensagens_padrao;
        },
        mensagensPadraoLoading(state) {
            return state.mensagens_loading;
        },
    },

    mutations: {
        SET_MENSAGENS_PADRAO(state, msgs) {
            state.mensagens_padrao = msgs
        },
        SET_MENSAGENS_PADRAO_LOADING(state, isLoading) {
            state.mensagens_loading = isLoading
        },
    },

    actions: {
        async buscaMensagens({ commit }, selecionado) {
            try {
                commit('SET_MENSAGENS_PADRAO_LOADING', true)
                const msgs = await ModuloPadraoServices.buscaMensagens(selecionado)
                commit('SET_MENSAGENS_PADRAO', msgs)
            } catch (e) {
                console.log(e)
            }
            commit('SET_MENSAGENS_PADRAO_LOADING', false)
        },
        async buscaMensagensPorTipo({ commit }) {
            try {
                commit('SET_MENSAGENS_PADRAO_LOADING', true)
                const msgs = await ModuloPadraoServices.buscaMensagensPorTipo()
                commit('SET_MENSAGENS_PADRAO', msgs)
            } catch (e) {
                console.log(e)
            }
            commit('SET_MENSAGENS_PADRAO_LOADING', false)
        },
        async adicionar({ dispatch }, msg) {
            try {
                console.log(msg)
                await ModuloPadraoServices.criarMensagem(msg)
                dispatch('buscaMensagens')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async editar({ dispatch }, msg) {
            try {
                await ModuloPadraoServices.editarMensagem(msg)
                dispatch('buscaMensagens')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async remover({ dispatch }, msgId) {
            try {
                await ModuloPadraoServices.removerMensagem(msgId)
                dispatch('buscaMensagens')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
    },
}
