import omniApiHost from "./host";
import axios from "axios";

axios.defaults.baseURL = omniApiHost;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(response => response, error => {
	if (error.response?.status == 401 && location.pathname != "/login") location.href = "/login";
	if (error.response?.status == 403 && location.pathname != "/") location.href = "/";
	return Promise.reject(error);
});

const bearerToken = JSON.parse(localStorage.getItem("login"))?.token;
if (bearerToken) axios.defaults.headers.common = { Authorization: `Bearer ${bearerToken}` };