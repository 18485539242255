import UsuariosServices from '@/assets/js/services/UsuariosServices'

export default {
    namespaced: true,

    state: {
        lista_usuarios: null,
        lista_usuarios_status: null,
        lista_usuarios_perfis: null,
        is_usuario_online: false,
        mensagem_redefinicao_senha: null
    },

    getters: {
        listaUsuarios(state) {
            return state.lista_usuarios;
        },
        getUsuarioById: (state) => (id) => {
            return state.lista_usuarios ? state.lista_usuarios.find(x => x.Id === id) : ''
        },
        listaUsuariosStatus(state) {
            return state.lista_usuarios_status;
        },
        listaUsuariosPerfis(state) {
            return state.lista_usuarios_perfis;
        },
        isUsuarioOnline(state) {
            return state.is_usuario_online
        },
        mensagemRedefinicaoSenha(state) {
            return state.mensagem_redefinicao_senha
        }
    },

    mutations: {
        SET_USUARIOS(state, usuarios) {
            state.lista_usuarios = usuarios
        },
        SET_USUARIOS_STATUS(state, usuarios_status) {
            state.lista_usuarios_status = usuarios_status
        },
        SET_USUARIOS_PERFIS(state, perfis) {
            state.lista_usuarios_perfis = perfis
        },
        SET_IS_ONLINE(state, usuario_is_online) {
            state.is_usuario_online = usuario_is_online
        },
        SET_MENSAGEM_REDEFINICAO_SENHA(state, mensagem_redefinicao_senha) {
            state.mensagem_redefinicao_senha = mensagem_redefinicao_senha
        }
    },

    actions: {
        async buscaUsuarios({ commit }) {
            try {
                const usuarios = await UsuariosServices.getUsuarios()
                commit('SET_USUARIOS', usuarios)
                return
            } catch (e) {
                console.error(e)
                return
            }
        },
        async verificaEstaOnline({ commit }) {
            try {
                const isOnline = await UsuariosServices.getIsOnline();
                commit('SET_IS_ONLINE', isOnline)
                return
            } catch (e) {
                console.error(e);
            }
        },
        async adicionar({ dispatch }, usuario) {
            try {
                await UsuariosServices.postUsuario(usuario)
                dispatch('buscaUsuarios');
            } catch (e) {
                console.error(e);
            }
        },
        async editar({ dispatch }, usuario) {
            try {
                await UsuariosServices.putUsuario(usuario)
                dispatch('buscaUsuarios');
            } catch (e) {
                console.error(e);
            }
        },
        async remover({ dispatch }, id) {
            try {
                await UsuariosServices.removeUsuario(id)
                dispatch('buscaUsuarios')
            } catch (e) {
                console.error(e);
            }
        },
        async resetarSenha({ commit }, id) {
            try {
                let mensagemRedefinicaoSenha = await UsuariosServices.resetarSenha(id)

                commit('SET_MENSAGEM_REDEFINICAO_SENHA', mensagemRedefinicaoSenha)
            } catch (e) {
                console.error(e);
            }
        },
        async alterarAssinatura({ dispatch }, usuario) {
            try {
                await UsuariosServices.alterarAssinatura(usuario)
                
                dispatch('buscaUsuarios')
            } catch (e) {
                console.error(e);
            }
        },
        async alterarDisponibilidadeAtendimento({ dispatch }) {
            try {
                await UsuariosServices.alterarDisponibilidadeAtendimento()
                
                dispatch('buscaUsuarios')
                dispatch('buscaUsuariosStatus')
            } catch (e) {
                console.error(e);
            }
        },
        async buscaUsuariosStatus({ commit }) {
            try {
                const usuariosStatus = await UsuariosServices.getUsuariosStatus()
                commit('SET_USUARIOS_STATUS', usuariosStatus)
            } catch (e) {
                console.error(e);
            }
        },
        async buscaUsuariosPerfis({ commit }) {
            try {
                const usuariosPerfis = await UsuariosServices.getUsuariosPerfis()
                commit('SET_USUARIOS_PERFIS', usuariosPerfis)
            } catch (e) {
                console.error(e);
            }
        },
    },
}
