import PausasServices from '../../assets/js/services/PausasServices'

export default {
    namespaced: true,

    state: {
        pausasComFiltro: [],
        pausas: []
    },

    getters: {
        getPausas(state) {
            return state.pausas;
        },
        getPausasComFiltro(state) {
            return state.pausasComFiltro;
        }
    },

    mutations: {
        SET_PAUSAS(state, dados) {
            state.pausas = dados;
        },
        SET_PAUSAS_COM_FILTRO(state, dados) {
            state.pausasComFiltro = dados;
        },
    },

    actions: {
        async buscarPausas({ commit }) {
            try {
                const response = await PausasServices.buscarPausas();
                commit('SET_PAUSAS', response);
            } catch (e) {
                console.log('BuscarPausas', e)
            }
        },
        async buscarPausasComFiltro({ commit }, dados) {
            try {
                const response = await PausasServices.buscarPausasComFiltro(dados);
                commit('SET_PAUSAS_COM_FILTRO', response);
            } catch (e) {
                console.log('BuscarPausas', e)
            }
        },
        async salvarOuEditarPausa({ dispatch }, dados) {
            try {
                await PausasServices.salvarOuEditarPausa(dados)
                dispatch('buscarPausasComFiltro', { 'Nome': null, 'Offset': 0 });
            } catch (e) {
                console.log('salvaPausa', e);
            }
        }
    },

}