import EmpresasLicencasServices from '@/assets/js/services/EmpresasLicencasServices'

export default {
    namespaced: true,

    state: {
        lista_empresas: [],
        lista_licencas: [],
        lista_empresa_modulos_habilitados: []
    },

    getters: {
        listaEmpresas(state) {
            return state.lista_empresas;
        },
        listaLicencas(state) {
            return state.lista_licencas;
        },
        listaEmpresaModulosHabilitados(state) {
            return state.lista_empresa_modulos_habilitados;
        }
    },

    mutations: {
        SET_EMPRESAS(state, empresas) {
            state.lista_empresas = empresas
        },
        SET_LICENCAS(state, licencas) {
            state.lista_licencas = licencas
        },
        SET_MODULOS_HABILITADOS(state, modulosHabilitados) {
            state.lista_empresa_modulos_habilitados = modulosHabilitados
        }
    },

    actions: {
        // EMPRESAS
        async buscaEmpresas({ commit, dispatch }) {
            try {
                const empresas = await EmpresasLicencasServices.getEmpresas()
                commit('SET_EMPRESAS', empresas)
                dispatch('buscaEmpresasModulosHabilitados')
                return empresas
            } catch (e) {
                console.log(e)
                return
            }
        },
        async adicionar({ dispatch }, empresaLicenciada) {
            try {
                await EmpresasLicencasServices.postEmpresa(empresaLicenciada)
                dispatch('buscaEmpresas')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async editar({ dispatch }, empresaLicenciada) {
            try {
                await EmpresasLicencasServices.putEmpresa(empresaLicenciada)
                dispatch('buscaEmpresas')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async remover({ dispatch }, empresaId) {
            try {
                await EmpresasLicencasServices.deleteEmpresa(empresaId)
                dispatch('buscaEmpresas')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async alterarEmpresaStatusAtividade({ dispatch }, empresaId) {
            try {
                await EmpresasLicencasServices.putEmpresaStatusAtividade(empresaId)
                dispatch('buscaEmpresas')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async buscaEmpresasModulosHabilitados({ commit }) {
            try {
                const empresaModulosHabilitados = await EmpresasLicencasServices.getEmpresaModulosHabilitados()
                commit('SET_MODULOS_HABILITADOS', empresaModulosHabilitados)
                return
            } catch (e) {
                console.log(e)
                return
            }
        },

        // LICENÇAS
        async buscaLicencas({ commit }) {
            try {
                const licencas = await EmpresasLicencasServices.getLicencas()
                commit('SET_LICENCAS', licencas)
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async adicionarLicenca({ dispatch }, licenca) {
            try {
                await EmpresasLicencasServices.postLicenca(licenca)
                dispatch('buscaEmpresas')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async atualizar({ dispatch }, licenca) {
            try {
                await EmpresasLicencasServices.putLicenca(licenca)
                dispatch('buscaEmpresas')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },

    },
}
