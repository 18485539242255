export default {
    namespaced: true,

    state: {
        funcao_mostrar_sucesso: null,
        funcao_mostrar_erro: null,
    },

    mutations: {
        SET_MOSTRAR_SUCESSO(state, funcaoDeMostrarSucesso) {
            state.funcao_mostrar_sucesso = funcaoDeMostrarSucesso
        },
        SET_MOSTRAR_ERRO(state, funcaoDeMostrarErro) {
            state.funcao_mostrar_erro = funcaoDeMostrarErro
        },
    },

    actions: {
        init({ commit }, funcoes) {
            commit('SET_MOSTRAR_SUCESSO', funcoes?.sucesso)
            commit('SET_MOSTRAR_ERRO', funcoes?.erro)
        },
        mostrarSucesso({ state }, msg) {
            state.funcao_mostrar_sucesso(msg)
        },
        mostrarErro({ state }, msg) {
            state.funcao_mostrar_erro(msg)
        },
    },
}