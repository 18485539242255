import CanaisServices from '@/assets/js/services/CanaisServices'

export default {
    namespaced: true,

    state: {
        lista_canais: null,
        lista_tipos_canais: null,
        lista_tipos_canais_habilitados: null,
        canais_usuario_logado: null
    },

    getters: {
        listaCanais(state) {
            return state.lista_canais;
        },
        listaTiposCanais(state) {
            return state.lista_tipos_canais;
        },
        TiposCanaisHabilitados(state) {
            return state.lista_tipos_canais_habilitados;
        },
        canaisUsuarioLogado(state) {
            return state.canais_usuario_logado;
        }
    },

    mutations: {
        SET_CANAIS(state, canais) {
            state.lista_canais = canais
        },
        SET_TIPOS_CANAIS(state, tipos) {
            state.lista_tipos_canais = tipos
        },
        SET_CANAIS_HABILITADOS(state, canais) {
            state.lista_tipos_canais_habilitados = canais
        },
        SET_CANAIS_USUARIO(state, canais) {
            state.canais_usuario_logado = canais
        }
    },

    actions: {
        async buscaCanais({ commit }) {
            try {
                const canais = await CanaisServices.getCanais()
                commit('SET_CANAIS', canais)
                return
            } catch(e) {
                console.log(e)
                return
            }
        },
        async adicionar({ dispatch }, canal) {
            try {
                await CanaisServices.adicionar(canal)
                dispatch('buscaCanais')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async editar({ dispatch }, canal) {
            try {
                await CanaisServices.editar(canal)
                dispatch('buscaCanais')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async remover({ dispatch }, id) {
            try {
                await CanaisServices.remover(id)
                dispatch('buscaCanais')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async buscaTiposCanais({ commit }) {
            try {
                const tipos = await CanaisServices.getTiposCanais()
                commit('SET_TIPOS_CANAIS', tipos)
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async buscaTiposCanaisHabilitados({ commit }) {
            try {
                const canaisHabilitados = await CanaisServices.getTiposCanaisHabilitados()
                commit('SET_CANAIS_HABILITADOS', canaisHabilitados)
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async putCanalPergunta({ dispatch }, canal) {
            try {
                await CanaisServices.putCanalPergunta(canal)
                dispatch('buscaCanais')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async putCanalSetor({ dispatch }, canal) {
            try {
                await CanaisServices.putCanalSetor(canal)
                dispatch('buscaCanais')
                return
            } catch (e) {
                console.log(e)
            }
        },
        async buscaCanaisUsuarioLogado({ commit }, idUsuario) {
            
            try {
                const canais = await CanaisServices.getCanaisUsuario(idUsuario)
                commit('SET_CANAIS_USUARIO', canais)
                return
            } catch(e) {
                console.log(e)
                return
            }
        },
    },
}
