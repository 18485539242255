import axios from "axios";
import LogServices from "@/assets/js/services/LogServices";

const URL_API = "/api/arvore-decisoes";

const get = async resource => {
	let source = `${URL_API}${resource ? `/${resource}` : ""}`;
	try {
		const { data } = await axios.get(source);
		return data;
	} catch (e) {
		LogServices.logErro(e, `GET ${source}`);
		throw (e);
	}
}

const post = async (resource, entity) => {
	let source = `${URL_API}${resource ? `/${resource}` : ""}`;
	try {
		const { data } = await axios.post(source, entity);
		return data;
	} catch (e) {
		LogServices.logErro(e, `POST ${source}`);
		throw (e);
	}
}

const put = async (resource, entity) => {
	let source = `${URL_API}${resource ? `/${resource}` : ""}`;
	try {
		const { data } = await axios.put(source, entity);
		return data;
	} catch (e) {
		LogServices.logErro(e, `PUT ${source}`);
		throw (e);
	}
}

const remove = async resource => {
	let source = `${URL_API}/${resource}`;
	try {
		const { data } = await axios.delete(source);
		return data;
	} catch (e) {
		LogServices.logErro(e, `DELETE ${source}`);
		throw (e);
	}
}

const getCanaisCompativeis = async () => await get("canais-compativeis");

const postPergunta = async (arvoreId, pergunta) => await post(`${arvoreId}/perguntas`, pergunta);
const putPergunta = async pergunta => await put(`perguntas`, pergunta);
const removePergunta = async perguntaId => await remove(`perguntas/${perguntaId}`);

const postPerguntaOpcao = async (perguntaId, opcao) => await post(`perguntas/${perguntaId}/opcoes`, opcao);
const putPerguntaOpcao = async opcao => await put(`perguntas/opcoes`, opcao);
const removePerguntaOpcao = async opcaoId => await remove(`perguntas/opcoes/${opcaoId}`);

export default {
	get,
	post,
	put,
	remove,
	getCanaisCompativeis,
	postPergunta,
	putPergunta,
	removePergunta,
	postPerguntaOpcao,
	putPerguntaOpcao,
	removePerguntaOpcao
}