import axios from 'axios';
import { refreshUsuarioLogado } from '@/assets/js/providers/usuario-logado';

const state = {
    status: false,
    usuario: null,
    token: null,
    mensagemErro: null,

    dadosSenhaAlterada: null,
};

const getters = {
    usuarioAutenticado: state => !!state.usuario,
    authStatus: state => state.status,
    authDadosUsuario: state => state.usuario,
    getSenhaDados: state => state.dadosSenhaAlterada,
    getModulosAtivos: state => state.usuario.ModulosEmpresa.split(',')
};
function generateFingerprint() {
    const fingerprintData = {
      userAgent: navigator.userAgent,
      language: navigator.language,
      platform: navigator.platform,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      colorDepth: window.screen.colorDepth,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  
    const fingerprintString = JSON.stringify(fingerprintData);
    // Utilize algum algoritmo de hash para criar um identificador único
    const fingerprint = hashCode(fingerprintString);
  
    return fingerprint;
  }

    var dataAtual = new Date();

    dataAtual.setTime(dataAtual.getTime() + (8 * 60 * 60 * 1000)); // 8 horas em milissegundos

    var dataExpiracao = dataAtual.toUTCString();
  
  // Função de hash (pode ser um simples exemplo)
  function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Converte para um inteiro de 32 bits
    }
    return hash;
}  

const clientFingerprint = generateFingerprint();

const actions = {
    login({ commit, dispatch }, dados) {
        dados.Fingerprint = generateFingerprint();

        return new Promise((resolve, reject) => {
            commit('authRequest')
            axios.post(`/api/autenticacao/login`, dados)
                .then(res => {
                    if (res.data.Sucesso) {
                        axios.defaults.headers.common = { 'Authorization': `Bearer ${res.data.Dados.token}` };
                        localStorage.setItem('login', JSON.stringify(res.data.Dados));
                        refreshUsuarioLogado();
                        dispatch('usuarios/buscaUsuarios', null, { root: true });
                        commit('authSuccessoValidacao', res.data.Dados);
                        resolve(res);
                    } else {
                        commit('authErroValidacao', res.data)
                        reject('Nao autenticado');
                    }
                })
                .catch(err => {
                    commit('authFalhou')
                    localStorage.removeItem('login');
                    reject(err);
                })

        })
    },

    async verifica({ commit, state, dispatch }) {
        try {
            const response = await axios.get(`/api/autenticacao/verifica/${clientFingerprint}`)
            dispatch('usuarios/verificaEstaOnline', null, { root: true });
            if (!response) {
                return false
            }

            // Se a store ainda não foi inicializada, inicializa com dados do localstorage.
            if (!state.status) {
                let usuario;
                try {
                    usuario = JSON.parse(localStorage.getItem('login'))
                } catch (e) {
                    usuario = null
                }

                commit('authSuccessoValidacao', usuario);
            }

            return true
        } catch (e) {
            if (e.response && e.response.status === 401) {
                console.log('nao autorizado')
            } else {
                console.log('erro', e)
            }
            commit('authFalhou')
            return false
        }
    },

    logout({ commit }) {
        axios.post(`/api/autenticacao/sair`)
            .then(res => { console.log(res) })
            .catch(err => { console.log(err) })

        commit('logOut')
        localStorage.removeItem('login');
        delete axios.defaults.headers.common['Authorization'];
    },

    deslogarUsuario({ commit }, userName) {

        axios.post(`/api/autenticacao/deslogarUsuario?userName=${userName}`)
            .then(res => { console.log('Deslogado!') })
            .catch(err => { console.log(err) })
    },

    alterarSenha({ commit, getters }, senha) {


        let usuario = {
            'usuarioId': getters.authDadosUsuario.usuarioId,
            'senhaAtual': senha.atual,
            'senhaNova': senha.nova
        }


        return axios
            .post(`/api/autenticacao/senha/alterar`, usuario)
            .then(res => {
                commit('authAlterarSenha', res.data);
                return res.data;
            })
            .catch(err => {
                console.log(err);
            });
    },
    alterarNome({ commit, getters }, nome) {


        let usuario = {
            'UsuarioId': getters.authDadosUsuario.usuarioId,
            'Nome': nome,
        }


        return axios
            .post(`/api/autenticacao/alterarNome`, usuario)
            .then(res => {
                commit("setNome", res.data.Nome)
                return res.data;
            })
            .catch(err => {
                console.log(err);
            });
    }
};

const mutations = {
    authRequest(state) {
        state.status = false
    },
    authSuccessoValidacao(state, dados) {
        state.status = true
        state.usuario = dados
        state.token = dados.token
        state.mensagemErro = ''
    },
    authErroValidacao(state, dados) {
        state.status = false
        state.mensagemErro = dados.Mensagem;
    },
    logOut(state) {
        state.status = false
        state.usuario = ''
        state.token = ''
        state.mensagemErro = ''
    },
    authFalhou(state) {
        state.status = false
    },
    authAlterarSenha(state, dados) {
        state.dadosSenhaAlterada = dados;
    },
    setNome(state, nome) {
        
        console.log(state.usuario)
        state.usuario.usuarioNome = nome
        //state.dados.Nome = nome;
        //state.dados.NormalizedUserName = nome;
        //state.dados.UserName = nome;
        
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};