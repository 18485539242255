import ChatConfigServices from '@/assets/js/services/ChatConfigServices'

export default {
    namespaced: true,

    state: {
        chat_configuracao: null
    },

    getters: {
        getChatConfiguracao(state) {
            return state.chat_configuracao;
        },
    },

    mutations: {
        SET_CHAT_CONFIGURACAO(state, config) {
            state.chat_configuracao = config
        }
    },

    actions: {
        async buscaChatConfiguracao({ commit }) {
            try {
                const chatConfig = await ChatConfigServices.getChatConfig()
                commit('SET_CHAT_CONFIGURACAO', chatConfig)
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async salvarChatConfiguracao({ dispatch }, novaConfig) {
            try {
                await ChatConfigServices.postChatConfig(novaConfig)

                dispatch('buscaChatConfiguracao')
                return
            } catch (e) {
                console.log(e)
                return
            }
        }
    },
}