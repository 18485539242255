import axios from 'axios'
import LogServices from '@/assets/js/services/LogServices'

const URL_API = '/api'

const buscaMensagens = async (selecionado) => {
    try {
        const { data } = await axios.get(`${URL_API}/ModuloPadrao/mensagens?offset=${selecionado}`)
        return data ?? null
    } catch (e) {
        LogServices.logErro(e, 'buscaMensagensModuloPadrao')
        throw (e)
    }
}

const buscaMensagensPorTipo = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/ModuloPadrao/mensagens-tipo`)
        return data ?? null
    } catch (e) {
        LogServices.logErro(e, 'buscaMensagensPorTipo')
        throw (e)
    }
}

const criarMensagem = async (mensagem) => {
    try {
        let novaMensagem = { ...mensagem }
        if (novaMensagem.Id !== undefined) {
            delete novaMensagem.Id
        }

        const { data } = await axios.post(`${URL_API}/ModuloPadrao/CadastroMensagem`, novaMensagem)
        return data ?? null
    } catch (e) {
        LogServices.logErro(e, 'criarMensagemModuloPadrao')
        throw (e)
    }
}

const editarMensagem = async (mensagem) => {
    try {
        console.log(mensagem)
        const { data } = await axios.put(`${URL_API}/ModuloPadrao/EditarMensagem`, mensagem)
        return data ?? null
    } catch (e) {
        LogServices.logErro(e, 'editarMensagemModuloPadrao')
        throw (e)
    }
}

const removerMensagem = async (idMsg) => {
    try {
        const { data } = await axios.delete(`${URL_API}/ModuloPadrao/ExcluiMensagem/${idMsg}`)
        return data ?? null
    } catch (e) {
        LogServices.logErro(e, 'removerMensagemModuloPadrao')
        throw (e)
    }
}

const buscaMensagensDisponiveis = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/ModuloPadrao/mensagens-disponiveis`)
        return data ?? null
    } catch (e) {
        LogServices.logErro(e, 'buscaMensagensDisponiveis')
        throw (e)
    }
}

export default {
    buscaMensagens,
    buscaMensagensPorTipo,
    criarMensagem,
    editarMensagem,
    removerMensagem,
    buscaMensagensDisponiveis,
}
