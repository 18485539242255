import Vue from 'vue'
import Vuex from 'vuex'

import authentication from './modules/authentication'
import atendimento from './modules/atendimento'
import atendimentoEmail from './modules/atendimentoEmail'
import usuarios from './modules/usuarios'
import usuarioSetor from './modules/usuarioSetor'
import setores from './modules/setores'
import canais from './modules/canais'
import chatPerguntas from './modules/chatPerguntas'
import tabulacao from './modules/tabulacao'
import tabulacaoItens from './modules/tabulacaoItens'
import empresasLicencas from './modules/empresasLicencas'
import arvore from './modules/arvore'
import chatConfiguracao from './modules/chatConfiguracao'
import moduloEmail from './modules/moduloEmail'
import moduloPadrao from './modules/moduloPadrao'
import discoVirtual from './modules/discoVirtual'
import mostradorDeMensagens from './modules/mostradorDeMensagens'
import modulos_integracoes from './modules/modulos_integracoes'
import salaGrupos from './modules/salaGrupos'
import pausas from './modules/pausas'
import usuariosPausados from './modules/usuariosPausados'

const pluginTrataErros = store => {
    store.subscribeAction({
        error: (action, state, error) => {
          console.log(`error action ${action.type}`)
          console.error(error)
        }
    })
}

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading_global: false,
        loading_page: false,
    },
    getters: {
        loadingGlobal(state) {
            return state.loading_global;
        },
        loadingPage(state) {
            return state.loading_page;
        },
    },
    mutations: {
        SET_LOADING_GLOBAL (state, estaCarregando) {
            state.loading_global = estaCarregando;
        },
        SET_LOADING_PAGE (state, pageLoading) {
            state.loading_page = pageLoading;
        },
    },

    actions: {
        setLoadingGlobal({ commit }, estaCarregando) {
            // Tempo máximo pois a tela toda pode ficar bloqueada
            const TEMPO_MAXIMO_LOADING = 10000
            // Tempo mínimo para dar um efeito visual mínimo
            const TEMPO_MINIMO = 300
            if(estaCarregando) {
              commit('SET_LOADING_GLOBAL', estaCarregando);
              setTimeout(() => {
                commit('SET_LOADING_GLOBAL', false);
              }, TEMPO_MAXIMO_LOADING);
            } 
            else {
              setTimeout(() => {
                commit('SET_LOADING_GLOBAL', false);
              }, TEMPO_MINIMO);
            }
        },
        setLoadingPage({ commit }, pageLoading) {
            if (pageLoading) {
                commit('SET_LOADING_PAGE', pageLoading);
            } 
            else {
                commit('SET_LOADING_PAGE', false);
            }
        },
    },

    modules: {
        authentication,
        atendimento,
        usuarios,
        usuarioSetor,
        setores,
        canais,
        chatPerguntas,
        tabulacao,
        tabulacaoItens,
        empresasLicencas,
        arvore,
        chatConfiguracao,       
        moduloEmail,
        moduloPadrao,
        discoVirtual,
        atendimentoEmail,
        mostradorDeMensagens,
        modulos_integracoes,
        salaGrupos,
        pausas,
        usuariosPausados
    },

    plugins: [pluginTrataErros]
});
