import UsuariosPausadosServices from '@/assets/js/services/UsuariosPausadosServices'
import RelatoriosConsultasServices from '../../assets/js/services/RelatoriosConsultasServices';

export default {
    namespaced: true,

    state: {
        nomePausa: '',
        isUsuarioPausado: false,
        contagem: 0,
        relarotios: [],
        quantidadeDeDados: 0,
        selecionadoPaginacaoUsuarios: 1,
        selecionadoPaginacaoPausas: 1,
        tempoAndQuantidadeTotal: null
    },

    getters: {
        getRelatorioPausasUsuarios(state) {
            return state.relarotios
        },
        getQuantidadeDeDados(state) {
            return state.quantidadeDeDados
        },
        getSelecionadoPaginacaoUsuarios(state) {
            return state.selecionadoPaginacaoUsuarios
        },
        getSelecionadoPaginacaoPausas(state) {
            return state.selecionadoPaginacaoPausas
        },
        getIsUsuarioPausado(state) {
            return state.isUsuarioPausado
        },
        contagem(state) {
            return state.contagem
        },
        getTempoAndQuantidadeTotal(state) {
            return state.tempoAndQuantidadeTotal
        },
        getNomePausa(state) {
            return state.nomePausa
        },
    },

    mutations: {
        SET_RELATORIOS(state, relarotios) {
            state.relarotios = relarotios;
            state.quantidadeDeDados = relarotios.length === 0 ? 0 : relarotios[0].QuantidadeDeDados;
        },
        SET_SELECIONADO_PAGINACAO_USUARIOS(state, offsetUsuarios) {
            state.selecionadoPaginacaoUsuarios = offsetUsuarios;
        },
        SET_SELECIONADO_PAGINACAO_PAUSAS(state, offsetPausas) {
            state.selecionadoPaginacaoPausas = offsetPausas
        },
        SET_IS_PAUSAR(state, isUsuarioPausado) {
            state.isUsuarioPausado = isUsuarioPausado;
        },
        SET_TEMPO(state, contagem) {
            state.contagem = contagem;
        },
        SET_TEMPO_AND_QUANTIDADE_TOTAL(state, tempoAndQuantidadeTotal) {
            state.tempoAndQuantidadeTotal = tempoAndQuantidadeTotal;
        },
        SET_NOME_PAUSA(state, nomePausa) {
            state.nomePausa = nomePausa;
        }
    },

    actions: {
        async filtroOuPadraoRelatorioDePausas({ commit }, dadosFiltro) {
            var relatorioFiltrado = await RelatoriosConsultasServices.filtroOuPadraoRelatorioDePausas(dadosFiltro);
            commit('SET_SELECIONADO_PAGINACAO_USUARIOS', dadosFiltro.offsetUsuario + 1);
            commit('SET_SELECIONADO_PAGINACAO_PAUSAS', dadosFiltro.offsetPausas + 1);
            commit('SET_RELATORIOS', relatorioFiltrado.RelatoriosUsuariosPausas);
            commit('SET_TEMPO_AND_QUANTIDADE_TOTAL', relatorioFiltrado.TempoAndQuantidadeTotal);
        },
        async verificarUsuarioPausado({ commit }) {
            var dadosUsuarioPausado = await UsuariosPausadosServices.verificarUsuarioPausado()
            commit('SET_IS_PAUSAR', dadosUsuarioPausado.IsPausado)
            commit('SET_NOME_PAUSA', dadosUsuarioPausado.NomePausa)
            if (dadosUsuarioPausado.IsPausado) commit('SET_TEMPO', dadosUsuarioPausado.Contagem);
        },
        async pausar({ commit, dispatch }, isPause) {
            dispatch('verificarUsuarioPausado')
            commit('SET_IS_PAUSAR', isPause)
        },
        async zerarInicio({ commit }) {
            commit ('SET_TEMPO', 0)
        },
        async exportarRelatorio({ }, dadosFiltroParaExportacao) {
            await RelatoriosConsultasServices.exportarRelatorio(dadosFiltroParaExportacao)
        }
    },

}