import ItensTabulacaoServices from '@/assets/js/services/TabulacaoServices'

export default {
    namespaced: true,

    state: {
        lista_itens_tabulacoes: null,
    },

    getters: {
        listaItensTabulacoes(state) {
            return state.lista_itens_tabulacoes
        },
    },

    mutations: {
        SET_ITENS_TABULACOES(state, itens_tabulacoes) {
            state.lista_itens_tabulacoes = itens_tabulacoes
        },
    },

    actions: {
        async buscaItensTabulacoes({ commit }) {
            try {
                const itensTabulacoes = await ItensTabulacaoServices.getItensTabulacoes()
                commit('SET_ITENS_TABULACOES', itensTabulacoes)
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async buscaItensByTabulacao({ commit }, idTabulacao) {
            try {
                const itensTabulacao = await ItensTabulacaoServices.getItensByTabulacaoId(idTabulacao)
                commit('SET_ITENS_TABULACOES', itensTabulacao)
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async adicionar({ dispatch }, itemTabulacao) {
            try {
                await ItensTabulacaoServices.adicionar(itemTabulacao)
                dispatch('buscaItensTabulacoes')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async editar({ dispatch }, itemTabulacao) {
            try {
                await ItensTabulacaoServices.editar(itemTabulacao)
                dispatch('buscaItensTabulacoes')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async remover({ dispatch }, id) {
            try {
                await ItensTabulacaoServices.remover(id)
                dispatch('buscaItensTabulacoes')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },

    },
}
