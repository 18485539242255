import ArvoreServices from '../../assets/js/services/ArvoreDecisoesServices'

export default{
    namespaced:true,

    state:{
        lista_perguntas:[],
        canais_disponiveis:[],
        lista_perguntas_loading:false,        

        pergunta_selecionada:[], 
        pergunta_selecionada_loading:false, 

        lista_respostas_pergunta_selecionada:[],
        lista_respostas_loading:false,

        estrutura_arvore:{},
        estrutura_arvore_loading:false,

    },

    getters:{
        canaisDiponiveis(state) {
            return state.canais_disponiveis;
        },listaPergunta(state){
            return state.lista_perguntas;
        },
        listaPerguntaLoading(state){
            return state.lista_perguntas_loading;
        },
        perguntaSelecionada(state){
            return state.pergunta_selecionada;
        },
        perguntaSelecionadaLoading(state){
            return state.pergunta_selecionada_loading 
        },
        respostaSelecionada(state){
            return state.lista_respostas_pergunta_selecionada;
        }, 
        respostaSelecionadaLoad(state){
            return state.lista_respostas_loading;
        }, 
        
        estruturaArvore(state){
            return state.estrutura_arvore;
        },

        estruturaArvoreLoading(state){
            return state.estrutura_arvore_loading
        }

    },

    mutations:{ 
        SET_LISTA_PERGUNTAS(state,lista){
            state.lista_perguntas = lista;
            state.lista_perguntas_loading = true;
        },
        SET_CANAIS_DISPONIVEIS(state, lista) {
            state.canais_disponiveis = lista;
            
        },
        SET_LISTA_RESPOSTAS(state,lista){            
            state.lista_respostas_pergunta_selecionada = lista;
            state.lista_respostas_loading = true;
        },

        SET_DESCARTE(state){
            state.dentro_drop_area = !state.dentro_drop_area;
        },

        SET_ESTRUTURA_ARVORE(state,estrutura){
            state.estrutura_arvore = estrutura;
            state.estrutura_arvore_loading = true;
        },

        SET_PERGUNTA(state,codigo){
            
            //procura qual a pergunta apartir do codigo dela
            let pergunta = state.lista_perguntas.find(pergunta => pergunta.CodigoInterno == codigo)
            let load = true;        
           //tratando para respotas vazias
            if (pergunta == null){  
                 pergunta=0;
                 load = false;
            }
            state.pergunta_selecionada = pergunta;
            state.pergunta_selecionada_loading = load;
        },      
        
        //tira a pergunta do array
        POP_PERGUNTA(state,pergunta){                  
            state.lista_perguntas.pop(pergunta);
        },

        PUSH_RESPOSTA(state,resposta){
            state.lista_respostas_pergunta_selecionada.push(resposta)
        },

        ATT_RESPOSTA(state,respostas){
            state.lista_respostas_pergunta_selecionada = respostas;
        }
               

    },

    actions: {   
        async excluiArvore({ commit }, id) {
            
            const arvoresDisponiveis = await ArvoreServices.excluiArvore(id);
            commit('SET_CANAIS_DISPONIVEIS', arvoresDisponiveis);
            return arvoresDisponiveis;
        },
        async salvarArvore({ commit }, dados) {
            
            const arvoresDisponiveis = await ArvoreServices.salvarArvore(dados);
            return arvoresDisponiveis;
        },
        async getCanaisDisponiveis({ commit }) {
            const canaisDisponiveis = await ArvoreServices.getCanaisDisponiveis();
            commit('SET_CANAIS_DISPONIVEIS', canaisDisponiveis);
            return canaisDisponiveis;
        },
        async getArvoresDisponiveis({ commit }) {
            const arvoresDisponiveis = await ArvoreServices.getArvoresDisponiveis();
            commit('SET_CANAIS_DISPONIVEIS', arvoresDisponiveis);
            return arvoresDisponiveis;
        },
        async buscaListaPerguntas({ commit }, canalId) {
            try {
                const listaPerguntas = await ArvoreServices.getListaPergunta(canalId)
                 commit ('SET_LISTA_PERGUNTAS',listaPerguntas)
                 return
            }catch(e){
                console.log(e)
                return
            }
        },
        async buscaListaRespostas({commit},perguntaId){             
              /*try{*/
                  const  listaRespostas = await ArvoreServices.buscaRespostaPergunta(perguntaId)
                  commit ('SET_LISTA_RESPOSTAS',listaRespostas)
              //}catch(e){
              //    console.log(e)
              //    return
              //}
        },

        async removeResposta({dispatch},resposta){
           /* try{*/                
                await ArvoreServices.deletaResposta(resposta.Id)
                dispatch('buscaListaRespostas',resposta.ChatPerguntaId)
                return
            //}catch(e){
            //    console.log(e)
            //    return
            //}
        },
        async removePergunta({dispatch},perguntaId){                
            try{
                 await ArvoreServices.deletaPergunta(perguntaId)                 
                 dispatch('buscaListaPerguntas')
                 return
            }catch(e){
                console.log(e)
                return
            }
        },
        async addPergunta({dispatch},pergunta){
           /* try{*/
                await ArvoreServices.addPergunta(pergunta)
                dispatch('buscaListaPerguntas')

            //}catch(e){
            //    console.log(e)
            //    return
            //}
        },
        async addPerguntaOpcao({dispatch},perguntaOpcao){
            let values = JSON.stringify(perguntaOpcao);
           /* try{*/
            await ArvoreServices.addPerguntaOpcao(perguntaOpcao)
                dispatch('buscaListaRespostas',perguntaOpcao.ChatPerguntaId)

            //}catch(e){
            //    console.log(e)
            //    return
            //}
        },
        async atualizaPerguntaSelciona({commit}){

            try{
                const pergunta = await ArvoreServices.pegaUltimaPergunta()
                commit('SET_PERGUNTA',pergunta.CodigoInterno)
            }catch(e){
                console.log(e)
                return 
            }
        },
        async tornarPadrao({dispatch},perguntaId){
            try{
                await ArvoreServices.tornarPadrao(perguntaId) 
                dispatch('buscaListaPerguntas')

            }catch(e){
                console.log(e)
                return
            }
        }

    },

}