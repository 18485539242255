import axios from 'axios'
import LogServices from '@/assets/js/services/LogServices'

const URL_API = '/api'

const buscarEnviados = async (numeroDaPagina, quantidade) => {
    try {
        const response = await axios.get(`${URL_API}/AtendimentoEmail/BuscaEnviados/${numeroDaPagina}${quantidade ? 'QntItens=' + quantidade : ''}`)
        return response ? response.data : null
    } catch (e) {
        LogServices.logErro(e, 'buscarEnviados')
        throw (e)
    }
}

const criarAtendimentoEmail = async (empresaId, canalId, usuarioId) => {
    try {
        const dados = {
            EmpresaId: empresaId,
            CanalId: canalId,
            UsuarioId: usuarioId
        }
        const response = await axios.post(`${URL_API}/AtendimentoEmail/CriarEmail/`, dados)
        return response ? response.data : null
    } catch (e) {
        LogServices.logErro(e, 'criarAtendimentoEmail')
        throw (e)
    }
}

const salvarArquivosEmail = async (arquivo) => {
    try {
        const arquivoAnexo = new FormData()
        arquivoAnexo.append('arquivoAnexo', arquivo, arquivo.name)

        const response = await axios.post(`${URL_API}/AtendimentoEmail/SalvarArquivosEnvio/`, arquivoAnexo, { headers: { 'Content-Type': 'multipart/form-data' } })
        return response ? response.data : null
    } catch (e) {
        LogServices.logErro(e, 'salvarArquivosEmail')
        throw (e)
    }
}
const enviarEmailMensagem = async (dados) => {
    const response = await axios.post(`${URL_API}/AtendimentoEmail/EnviaEmailMensagem/`, dados)
    return response ? response.data : null
}

const enviarEmail = async (atendimentoId, canalId, nomeArquivo, assunto, mensagem, emailPara, emailCc, normal) => {
    try {
        const dados = {
            AtendimentoId: atendimentoId,
            CanalId: canalId,
            NomeArquivo: nomeArquivo,
            MensagemEnvio: mensagem,
            AssuntoEmail: assunto,
            EmailPara: emailPara,
            EmailCc: emailCc,
            Normal: normal
        }
        if (normal) {
            const response = await axios.post(`${URL_API}/AtendimentoEmail/EnviaEmailSemAtendimento/`, dados)
            return response ? response.data : null
        } else {
            const response = await axios.post(`${URL_API}/AtendimentoEmail/EnvioEmail/`, dados)
            return response ? response.data : null
        }

    } catch (e) {
        LogServices.logErro(e, 'enviarEmail')
        if (e.response) {
            throw new Error(e.response.data)
        }
        throw (e)
    }
}
const enviarEmailDireto = async (dados) => {

    //const dados = {
    //    CanalId: canalId,
    //    NomeArquivo: nomeArquivo,
    //    MensagemEnvio: mensagem,
    //    AssuntoEmail: assunto,
    //    EmailPara: emailPara,
    //    EmailCc: emailCc,
    //    Normal: normal
    //}
    console.log("direto ", dados)
    const response = await axios.post(`${URL_API}/AtendimentoEmail/EnviaEmailDireto`, dados)
    return response ? response.data : null



}

export default {
    buscarEnviados,
    criarAtendimentoEmail,
    salvarArquivosEmail,
    enviarEmail,
    enviarEmailDireto,
    enviarEmailMensagem
}
