import ChatPerguntaServices from '@/assets/js/services/ChatPerguntaServices'

export default {
    namespaced: true,

    state: {
        lista_chat_perguntas: null,
    },

    getters: {
        listaChatPerguntas(state) {
            return state.lista_chat_perguntas;
        },
    },

    mutations: {
        SET_LISTA_CHAT_PERGUNTAS(state, perguntas) {
            state.lista_chat_perguntas = perguntas
        },
    },

    actions: {
        async buscaListaPerguntas({ commit }) {
            try {
                const perguntas = await ChatPerguntaServices.getListaPerguntas()
                commit('SET_LISTA_CHAT_PERGUNTAS', perguntas)
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
    },
}
