import TabulacaoServices from '@/assets/js/services/TabulacaoCrudServices'

export default {
    namespaced: true,

    state: {
        isSending: false,
        lista_tabulacoes: null,
        lista_tabulacoes_pai: null,
        lista_tipos: null,
        lista_tipos_clientes: null,

        isLoading: false
    },

    getters: {
        isLoading (state) {
            return state.isLoading
        },
        isSending (state) {
            return state.isSending
        },
        listaTabulacoes (state) {
            return state.lista_tabulacoes
        },
        listaTabulacoesPai(state) {
            return state.lista_tabulacoes_pai
        },
        listaTipos(state) {
            return state.lista_tipos
        },
        listaTiposClientes(state) {
            return state.lista_tipos_clientes
        },
    },

    mutations: {
        SET_ISLOADING(state, status) {
            state.isLoading = status
        },
        SET_ISSENDING(state, status) {
            state.isSending = status
        },
        SET_TABULACOES(state, tabulacoes) {
            state.lista_tabulacoes = tabulacoes
        },
        SET_TABULACOES_PAI(state, tabulacoesPai) {
            state.lista_tabulacoes_pai = tabulacoesPai
        },
        SET_TIPOS(state, tipos) {
            state.lista_tipos = tipos
        },
        SET_TIPOS_CLIENTES(state, tiposClientes) {
            state.lista_tipos_clientes = tiposClientes
        },
    },

    actions: {
        async buscaTabulacoes({ commit }) {
            try {
                const tabulacoes = await TabulacaoServices.getTabulacoes()
                commit('SET_TABULACOES', tabulacoes)
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async adicionar({ dispatch }, tabulacao) {
            try {
                await TabulacaoServices.adicionar(tabulacao)
                dispatch('buscaTabulacoes')
                dispatch('buscaTabulacoesPai')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async editar({ dispatch }, tabulacao) {
            try {
                await TabulacaoServices.editar(tabulacao)
                dispatch('buscaTabulacoes')
                dispatch('buscaTabulacoesPai')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async remover({ dispatch }, id) {
            try {
                await TabulacaoServices.remover(id)
                dispatch('buscaTabulacoes')
                dispatch('buscaTabulacoesPai')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async buscaTabulacoesPai({ commit }) {
            try {
                const tabulacoesPai = await TabulacaoServices.getTabulacoesPai()
                commit('SET_TABULACOES_PAI', tabulacoesPai)
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async buscaEnumTipos({ commit }) {
            try {
                const tipos = await TabulacaoServices.getEnumTipos()
                commit('SET_TIPOS', tipos)
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async buscaEnumTiposClientes({ commit }) {
            try {
                const tiposClientes = await TabulacaoServices.getEnumTiposClientes()
                commit('SET_TIPOS_CLIENTES', tiposClientes)
                return
            } catch (e) {
                console.log(e)
                return
            }
        },

        async buscaListasEnumTabulacoes({ dispatch }) {
            dispatch('buscaTabulacoesPai')
            dispatch('buscaEnumTipos')
            dispatch('buscaEnumTiposClientes')
        }
    },
}
